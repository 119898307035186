import { stringify } from 'query-string';
import useSWR from 'swr';
import { PlusMemberResponse } from '@/types/response/subsrv';
import { isSupportPlusMember } from '@@/netlify/functions/utils';
import fetcher from './credits/fetcher';
import { swrConfig } from './credits/alpcFetcher';
import { useAppContext } from './useApp';

function usePlusMember({ userId }: { userId: string }): {
  isLoading: boolean;
  data?: PlusMemberResponse;
} {
  const { app, brand, locale } = useAppContext();

  const queries = {
    app,
  };

  const swrKey =
    userId && isSupportPlusMember({ brand, locale }) ? `/subsrv/v1/prime/member/info?${stringify(queries)}` : null;
  const swrFetcher = async (url: string) =>
    fetcher({
      url,
      method: 'POST',
    });

  const { data, error, isLoading } = useSWR<PlusMemberResponse>(swrKey, swrFetcher, swrConfig);

  // if (error) {
  //   console.error(error);
  // }

  return {
    isLoading,
    data,
  };
}

export default usePlusMember;
