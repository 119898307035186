/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://5a9c0a32d890d344f661e39482fc4d18@o487797.ingest.us.sentry.io/4506012408676352',
  environment: process.env.ENVIRONMENT,
  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],

  tracesSampleRate: 0,
  tracesSampler() {
    return 0;
  },

  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    // 只上传部署出错 + 生产环境的页面崩溃
    if (
      String(event.tags?.type) === 'DeploymentError' ||
      (String(event.tags?.type) === 'CrashError' && process.env.ENVIRONMENT === 'production')
    ) {
      event.tags = { ...event.tags, brand: process.env.BRAND };
      return event;
    } else {
      return null;
    }
  },
});
