import { useAppContext } from '@/hooks/useApp';
import { useUserReAuth } from '@/hooks/useUser';
import { fetchProfile } from '@/services/identity';
import { getUserInfo, saveUserInfo } from '@/utils/helper';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCustomerToken } from '@/services/storefrontApi';
import Cookies from 'js-cookie';
import { parse } from 'query-string';
import * as Sentry from '@sentry/gatsby';
import { SHA256 } from 'crypto-js';
import usePlusMember from '@/hooks/usePlusMember';
import { UserContext } from './UserProviderContext';

export function UserProvider({ location, children }) {
  const { app, storeAccessToken } = useAppContext();
  const [user, setUser] = useState();
  const loading = useRef(false);
  const [userLoading, setUserLoading] = useState(true);
  const { mutate: reAuth } = useUserReAuth();
  const visitorEmailRef = useRef('');

  const visitor = useMemo(() => {
    return location.pathname.includes('/account/orders/') || location.pathname.includes('/account/order-returns/');
  }, [location]);

  const fetchData = useCallback(() => {
    loading.current = true;
    return Promise.all([
      fetchProfile({ app }).then((profileData) => {
        if ((visitorEmailRef.current && profileData.email === visitorEmailRef.current) || !visitorEmailRef.current) {
          setUser(profileData);
          saveUserInfo(app, profileData);
          setUserLoading(false);
          sessionStorage.removeItem('visitor');
          visitorEmailRef.current = '';
        }
      }),
      getCustomerToken({ app, storeAccessToken }),
    ])
      .then(() => {
        loading.current = false;
      })
      .catch((err) => {
        console.error('user fetchData error: ', err.message);
        loading.current = false;
        if (!visitorEmailRef.current) {
          reAuth();
        }
      });
  }, [reAuth, app, storeAccessToken]);

  useEffect(() => {
    if (!location.pathname.includes('/account') && !location.pathname.includes('/asset')) {
      setUserLoading(false);
      return;
    }

    const query = parse(window.location.search);
    if (query.ee) {
      try {
        sessionStorage.setItem('visitor', atob(query.ee));
      } catch (err) {
        console.error('visitor email error', err.message);
      }
    }

    // 游客身份
    const visitorInfo = sessionStorage.getItem('visitor');

    // 用户缓存
    const userInfoFromCache = getUserInfo(app);
    const customerToken = Cookies.get(`${app}-customer-token`);

    if (visitorInfo === userInfoFromCache?.email || !visitorInfo) {
      if (userInfoFromCache && customerToken) {
        setUser(userInfoFromCache);
        visitorEmailRef.current = '';
        sessionStorage.removeItem('visitor');
        // 不强制鉴权
        setUserLoading(false);
        return;
      }
    }

    if (visitorInfo && visitor) {
      // 支持游客访问
      setUser({
        email: visitorInfo,
      });
      setUserLoading(false);
      visitorEmailRef.current = visitorInfo;
    }

    if (loading.current) {
      return;
    }
    fetchData();
  }, [app, visitor, location.pathname, fetchData]);

  const { data: plusMember, isLoading: plusMemberLoading } = usePlusMember({ userId: user?.user_id });

  useEffect(() => {
    if (user) {
      Sentry.setTag('email', SHA256(user.email).toString());
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        isVisitor: !!visitorEmailRef.current,
        isLoading: userLoading || plusMemberLoading,
        refetch: fetchData,
        plusMember: plusMember?.data?.list?.length === 0 ? undefined : plusMember?.data,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
